<!--
 * @Author: yuwenwen
 * @Date: 2022-11-11 11:17:38
 * @LastEditors: Do not edit
 * @LastEditTime: 2022-11-11 11:31:29
 * @FilePath: \nc-funeral-mobile\src\views\mine\vrHall.vue
-->
<template>
  <div class="page-container vr-hall-page">
    <div class="hall-container">
      <div class="item" v-for="(item, index) in hallList" :key="index" @click="toPageHall(item)">
        <div class="tag">VR</div>
        <img class="vr-img" src="../../assets/images/screenshot.png" />
        <div class="hall-name">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCeremonyHallList } from "@/api/index.js";
export default {
  name: "VrHall",
  data() {
    return {
      hallList: [],
    };
  },
  created() {
    this.getRCeremonyHallList()
  },
  methods: {
    // 推荐礼厅
    async getRCeremonyHallList() {
      let [err, res] = await this.$awaitWrap(getCeremonyHallList());
      if (err) {
        return;
      }
      this.hallList = res.data.list;
      console.log("礼厅>>>", this.ceremonyHallList);
    },
    // 推荐礼厅跳转
    toPageHall(item) {
      // this.$router.push({ path: item.url });
      window.location.href = item.url;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/mine.less";
</style>